<template>
    <div>
        <div class="content-header">
            <span>自建单类型：</span>
            <el-select v-model="orderType" placeholder="请选择" style="width: 240px" @change="orderTypeChange">
                <el-option v-for="item in orderTypeList" :key="item" :value="item" :label="item"></el-option>
            </el-select>
        </div>
        <div v-if="showOrderType == '交接班管理'">
            <div class="content-header">
                <div class="span-style" />
                <span>搜索条件</span>
            </div>
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.gjz"></el-input>
                    </el-form-item>
                    <el-row class="margin-style"><el-col>
                            <el-button @click="onRest">重置</el-button>
                            <el-button type="primary" @click="onSubmit">批量催单</el-button>
                            <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                        </el-col></el-row>
                </el-form>
            </el-card>

            <div class="content-header">
                <div class="span-style" />
                <span>工单列表</span>
            </div>
            <el-button @click="exportToExcels('超时工单', tableData)">导出</el-button>
            <el-button @click="showCreateDialog = true">创建</el-button>
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />

                <el-table-column prop="gdh" label="工单号" sortable>
                </el-table-column>
                <el-table-column prop="gdbt" label="工单标题" sortable>
                    <template #default="scope">
                        <div class="cell-content" @click="toggleFullContent(scope.$index)">
                            <span v-if="!scope.row.showFullContent">{{ truncateText(scope.row.gdbt, 10) }}</span>
                            <span v-else>{{ scope.row.gdbt }}</span>
                            <el-icon v-if="!scope.row.showFullContent">
                                <ArrowDown />
                            </el-icon>
                            <i class="el-icon-arrow-down" v-if="!scope.row.showFullContent"></i>
                            <el-icon v-else>
                                <ArrowUp />
                            </el-icon>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop"
                    :label="column.label" sortable>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>

            <!-- 创建弹窗 -->
            <el-dialog title="创建工单" v-model="showCreateDialog" width="500px">
                <el-form :model="createForm" label-width="100px" class="create-dialog-form">
                    <!-- 原有表单元素 -->
                    <el-form-item label="工单类型" prop="gdlx">
                        <el-input v-model="createForm.gdlx"></el-input>
                    </el-form-item>
                    <el-form-item label="工单状态" prop="gdzt">
                        <el-input v-model="createForm.gdzt"></el-input>
                    </el-form-item>
                    <el-form-item label="派单人" prop="pdr">
                        <el-input v-model="createForm.pdr"></el-input>
                    </el-form-item>
                    <el-form-item label="处理人" prop="clr">
                        <el-input v-model="createForm.clr"></el-input>
                    </el-form-item>
                    <el-form-item label="到期时间" prop="dqsj">
                        <el-date-picker v-model="createForm.dqsj" type="date" placeholder="选择日期" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD  00:00:00"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="结单时间" prop="jdsj">
                        <el-date-picker v-model="createForm.jdsj" type="date" placeholder="选择日期" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD 00:00:00"></el-date-picker>
                    </el-form-item>
                    <!-- 新增的上传附件、图片和视频的表单元素 -->
                    <el-form-item label="上传附件">
                        <el-upload class="upload-attachment" action="#" :show-file-list="true"
                            :on-change="handleAttachmentChange" :file-list="createForm.wjs" :limit="1"
                            :on-exceed="handleAttachmentExceed" :on-remove="handleAttachmentRemove"
                            :before-upload="beforeAttachmentUpload" :auto-upload="false">
                            <el-button size="small" type="primary">点击上传附件</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="上传图片">
                        <el-upload class="upload-image" action="#" :show-file-list="true" :on-change="handleImageChange"
                            :file-list="createForm.tps" :limit="1" :on-exceed="handleImageExceed"
                            :on-remove="handleImageRemove" :before-upload="beforeImageUpload" :auto-upload="false"
                            accept="image/*">
                            <el-button size="small" type="primary">点击上传图片</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="上传视频">
                        <el-upload class="upload-video" action="#" :show-file-list="true" :on-change="handleVideoChange"
                            :file-list="createForm.sps" :limit="1" :on-exceed="handleVideoExceed"
                            :on-remove="handleVideoRemove" :before-upload="beforeVideoUpload" :auto-upload="false"
                            accept="video/*">
                            <el-button size="small" type="primary">点击上传视频</el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="showCreateDialog = false">取消</el-button>
                        <el-button type="primary" @click="handleCreate">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script setup>
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../components/exportUtils.js';
import { get, post } from '../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();
const orderType = ref('交接班管理');
const orderTypeList = ref(['交接班管理', '培训例会管理'])
const showOrderType = ref('交接班管理')
const orderTypeChange = (e) => {
    showOrderType.value = e
}
const exportToExcels = (tableName, rows) => {
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/ticket/cslistExport?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}
const handleRowDblclick = (row) => {
    router.push({ name: 'SelfBuildOrderDetail', params: { id: row.id } });
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const dateArr = ref('')
const formInline = ref({
    gjz: ''
})
const columns = [
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'pdr',
        label: '派单人'
    },
    {
        prop: 'clr',
        label: '处理人'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    }
]
const rowDetail = ref()

const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

const tableData = ref([])
const showCreateDialog = ref(false);
const createForm = ref({
    gdlx: '',
    gdzt: '',
    pdr: '',
    clr: '',
    dqsj: '',
    jdsj: '',
    wjs: [],
    tps: [],
    sps: [],
    wj: [],
    tp: [],
    sp: []
});

onMounted(() => {
    getTicketList(currentPage.value)
})
const truncateText = (text, length) => {
    if (text && text.length > length) {
        return text.slice(0, length) + '...';
    }
    return text;
}
const toggleFullContent = (index) => {
    tableData.value[index].showFullContent = !tableData.value[index].showFullContent || false;
}
const getTicketList = (currentPage) => {
    let queryString = objectToQueryString(formInline.value);
    let Satoken = localStorage.getItem('Satoken')
    const config = {
        'Satoken': Satoken
    }
    get('zjd/list?currentPage=' + currentPage + '&' + 'pageSize=' + pageSize.value + '&' + queryString, {}, config).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }
    })
}
const dateChange = (value) => {
    formInline.value.startDate = value[0]
    formInline.value.endDate = value[1]
}
const onRest = () => {
    dateArr.value = ""
    formInline.value = {
        gjz: '',
    }
}

// 处理附件上传
const handleAttachmentChange = (file, fileList) => {
    createForm.value.wjs = fileList;
}
const handleAttachmentExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一个附件，当前已上传 ${fileList.length} 个。`);
    }
}
const handleAttachmentRemove = (file, fileList) => {
    createForm.value.wjs = fileList;
}
const beforeAttachmentUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('附件大小不能超过 2MB!');
    }
    return isLt2M;
}

// 处理图片上传
const handleImageChange = (file, fileList) => {
    createForm.value.tps = fileList;
}
const handleImageExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一张图片，当前已上传 ${fileList.length} 张。`);
    }
}
const handleImageRemove = (file, fileList) => {
    createForm.value.tps = fileList;
}
const beforeImageUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
    }
    return isLt2M;
}

// 处理视频上传
const handleVideoChange = (file, fileList) => {
    createForm.value.sps = fileList;
}
const handleVideoExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一个视频，当前已上传 ${fileList.length} 个。`);
    }
}
const handleVideoRemove = (file, fileList) => {
    createForm.value.sps = fileList;
}
const beforeVideoUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('视频大小不能超过 2MB!');
    }
    return isLt2M;
}


const handleCreate = () => {
    // 先将文件上传到服务器，获取文件的服务器存储地址
    const uploadPromises = [];
    if (createForm.value.wjs.length > 0) {
        const attachmentUploadPromise = uploadFile(createForm.value.wjs[0].raw, '/attach/create');
        uploadPromises.push(attachmentUploadPromise);
    }
    if (createForm.value.tps.length > 0) {
        const imageUploadPromise = uploadFile(createForm.value.tps[0].raw, '/attach/create');
        uploadPromises.push(imageUploadPromise);
    }
    if (createForm.value.sps.length > 0) {
        const videoUploadPromise = uploadFile(createForm.value.sps[0].raw, '/attach/create');
        uploadPromises.push(videoUploadPromise);
    }

    Promise.all(uploadPromises).then((results) => {
        // 假设 results 是一个数组，存储上传成功的文件地址
        let attachmentUrl = {};
        let imageUrl = {};
        let videoUrl = {};
        if (results.length > 0) {
            attachmentUrl = results[0] || null;
            if (results.length > 1) {
                imageUrl = results[1] || null;
                if (results.length > 2) {
                    videoUrl = results[2] || null;
                }
            }
        }
        // 将文件地址添加到 createForm 中
        createForm.value.wj = attachmentUrl;
        createForm.value.tp = imageUrl;
        createForm.value.sp = videoUrl;
        // 发送创建工单的请求
        post('/zjd/create', createForm.value).then(response => {
            if (response.errorCode === 0) {
                // 创建成功，关闭弹窗并刷新列表
                showCreateDialog.value = false;
                getTicketList(currentPage.value);
                createForm.value = {
                gdlx: '',
                gdzt: '',
                pdr: '',
                clr: '',
                dqsj: '',
                jdsj: '',
                wjs: [],
                tps: [],
                sps: [],
                wj: null,
                tp: null,
                sp: null
            }
            } else {
                console.error('创建失败：', response.errorMessage);
            }
            
        }).catch(error => {
            console.error('网络错误：', error);
        });
    }).catch(error => {
        console.error('文件上传失败：', error);
    });
}


const uploadFile = (file, url) => {
    const formData = new FormData();
    if (file) {
        formData.append('file', file);
    }
    const urlHref = 'https://www.aiyuservice.com/api' + url
    // return fetch(urlHref, {
    //     method: 'POST',
    //     body: formData}).then(response => {
    //     if (response.errorCode === 0) {
    //         return response.result;
    //     } else {
    //         throw new Error('文件上传失败');
    //     }
    // }); 
    return post(urlHref, formData).then(response => {
        if (response.errorCode === 0) {
            return response.result;
        } else {
            throw new Error('文件上传失败');
        }
    });
}
</script>

<style>
.form-inline.el-form-item {
    width: 250px;
}

.content-header {
    margin-bottom: 10px;
    font-weight: bold;
}

.span-style {
    display: inline-block;
    height: 20px;
    width: 3px;
    background: #409eff;
    vertical-align: bottom;
    margin-right: 10px;
}

.box-card {
    margin-bottom: 30px;
}

.margin-style {
    margin-top: 10px;
}

.item-content {
    height: 40px;
}

.el-table tr {
    cursor: pointer;
}

.create-dialog-form .el-form-item__content .el-input {
    width: 240px !important;
}
</style>